// gatsby
import { Link } from 'gatsby'
// react
import React, { useState, useEffect } from 'react'
// react bootstrap components
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import Layout from 'components/layout'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Row from 'react-bootstrap/Row'
import Pagination from 'react-bootstrap/Pagination'
import Table from 'react-bootstrap/Table'
import Tooltip from 'react-bootstrap/Tooltip'
// ui
import 'bootstrap/dist/css/bootstrap.css'
import 'react-day-picker/lib/style.css'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { CgNotes } from 'react-icons/cg'
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'
import { FcDownload, FcSynchronize, FcBullish } from 'react-icons/fc'
// internal components
import AllStationOptions from 'components/options/station/all'
import AllUnitOptions from 'components/options/unit/all'
// internal functions
import {
  customDate,
  compareJsDateToDbDate
} from 'tools/date'
import {
  compareStation,
  compareUnit,
  compareCycle,
  compareOwner,
  compareTimestamp,
  compareNotes
} from 'tools/sort'
import { splitArray } from 'tools/util'
import download from 'downloadjs'
import downloadAndCompress from 'tools/downloadAndCompress'
import { GlobalDispatchContext } from 'context/global_context_provider'

const HistoryIndexPage = (props) => {
  const dispatch = React.useContext(GlobalDispatchContext)
  useEffect(() => {
    props.fetch()
  }, [])
  const [owner, setOwner] = useState('')
  const [day, setDay] = useState('')
  const [station, setStation] = useState('')
  const [unit, setUnit] = useState('')
  const [cycle, setCycle] = useState('')
  const [notes, setNotes] = useState('')
  const cases = (
    (props.data)
      ? props.data.hdata
      : null
  )
  const fullName = (
    (props.data)
      ? props.data.profile.familyName +
        ', ' +
        props.data.profile.givenName
      : ''
  )
  const [sortConfig, setSortConfig] = React.useState('')
  useEffect(()=>{
    console.log('checking for sort')
    if (props.sort) {
      console.log('user sort options present!')
      if (props.sort.hdata_key && props.sort.hdata_order) {
        const ascending = (props.sort.hdata_order === 'a') ? true : false
        setSortConfig([props.sort.hdata_key, ascending])
      }
    }
  }, [props.sort])
  const sorted = React.useMemo(() => {
    let sorte = null
    if (!cases) {
      return sorte
    } else if (!sortConfig) {
      sorte = (
        cases
          .sort((a, b) => compareTimestamp(a, b, false))
          .sort((a, b) => compareCycle(a, b, false))
          .sort(compareUnit)
          .sort(compareStation)
      )
    } else {
      const compareFunctions = {
        station: compareStation,
        unit: compareUnit,
        cycle: compareCycle,
        owner: compareOwner,
        timestamp: compareTimestamp,
        notes: compareNotes
      }
      console.log('compare functions')
      console.log(compareFunctions)
      const compareFunction = compareFunctions[sortConfig[0]]
      sorte = (
        cases
          .sort((a, b) => compareTimestamp(a, b, false))
          .sort(compareCycle)
          .sort(compareUnit)
          .sort(compareStation)
          .sort((a, b) => compareFunction(a, b, sortConfig[1]))
      )
    }
    if (owner) {
      sorte = sorte.filter(item => (
        item.owner.S.toLowerCase().includes(owner.toLowerCase())
      ))
    }
    if (day) {
      sorte = sorte.filter(item => (
        compareJsDateToDbDate(day, item.date.M)
      ))
    }
    if (station) {
      sorte = sorte.filter(item => item.station.S === station)
    }
    if (unit) {
      sorte = sorte.filter(item => item.unit.N === unit)
    }
    if (cycle) {
      sorte = sorte.filter(item => item.cycle.N === cycle)
    }
    if (notes) {
      sorte = sorte.filter(item => item.notes.S.includes(notes))
    }
    if (props.personalView && props.data) {
      sorte = sorte.filter(item => (item.owner.S === fullName))
    }
    return sorte
  }, [
    cases,
    sortConfig,
    owner,
    day,
    station,
    unit,
    cycle,
    notes,
    props.personalView,
    props.data
  ])
  const [selection, setSelection] = useState(null)
  const toggleSelection = (index) => {
    const copy = [...selection]
    copy[index] = !copy[index]
    setSelection(copy)
  }
  const getSelectedFileIds = () => (
    sorted
      .filter((x, i) => selection[i])
      .map(x => x.id.S)
  )
  const unselect = () => {
    setSelection([...selection].map(x => false))
  }
  useEffect(() => {
    if (sorted) {
      setSelection(sorted.map(x => false))
    }
  }, [sorted])
  const downloadFiles = async () => {
    const keys = getSelectedFileIds()
    downloadAndCompress('mco', 'historic', keys, dispatch)
  }
  const requestSort = key => {
    unselect()
    if (key === sortConfig[0] && !sortConfig[1]) {
      setSortConfig('')
    } else {
      console.log(sortConfig)
      console.log(key)
      const ascending = (
        !sortConfig ||
        sortConfig[0] !== key ||
        (sortConfig[0] === key && !sortConfig[1])
      )
      setSortConfig([key, ascending])
    }
  }
  const downloadUrl = (
      `https://${process.env.GATSBY_API_DOMAIN}/download/mco/historic/`
  )
  const graphUrl = '/mco/history/graph#'
  const [page, setPage] = useState(0)
  const nPages = (
    (sorted)
      ? Math.ceil(sorted.length / 20)
      : null
  )
  useEffect(() => {
    document.onkeydown = (e) => {
      if (e.key === 'ArrowRight' && nPages && page < nPages - 1) {
        setPage(page + 1)
      } else if (e.key === 'ArrowLeft' && nPages && page > 0) {
        setPage(page - 1)
      }
    }
  })
  const thisPagination = (
    (sorted && sorted.length > 20)
      ? (
        <Pagination>
          {
            [...Array(nPages).keys()].map(i => (
              <Pagination.Item
                key={'page-item-' + i}
                active={(i === page)}
                onClick={() => setPage(i)}
              >
                {i + 1}
              </Pagination.Item>
            ))
          }
        </Pagination>
        )
      : null
  )
  const tableBody = (
    (cases && sorted.length > 0 && selection)
      ? (splitArray(sorted, 20)[page]
          .map((item, index) =>
            <tr key={'hdata' + index.toString()}>
              <td>
                <Form.Check
                  checked={selection[page * 20 + index]}
                  onChange={() => toggleSelection(page * 20 + index)}
                />
              </td>
              <td>{props.appInfo.stations[item.station.S].name}</td>
              <td className='text-center'>{item.unit.N}</td>
              <td className='text-center'>{item.cycle.N}</td>
              <td>{item.owner.S}</td>
              <td>{customDate(item.date.M)}</td>
              <td>{item.date_range.S}</td>
              <td className='text-center'>{item.point_coverage.S}</td>
              <td>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  overlay={
                    <Tooltip id='button-tooltip-2'>
                      {
                        (item.notes)
                          ? (item.notes.S !== '')
                              ? item.notes.S
                              : 'none'
                          : 'none'
                      }
                    </Tooltip>
                  }
                >
                  {({ ref, ...triggerHandler }) => (
                    <Container
                      {...triggerHandler}
                      ref={ref}
                      className='p-0'
                    >
                      <Row xs='auto' className='justify-content-center'>
                        <Col xs='auto' className='p-0'>
                          {
                            (item.notes)
                              ? (item.notes.S !== '')
                                  ? <CgNotes />
                                  : null
                              : null
                          }
                        </Col>
                      </Row>
                    </Container>
                  )}
                </OverlayTrigger>
              </td>
              <td className='text-center'>
                <a href={downloadUrl + item.id.S}>
                  <FcDownload />
                </a>
              </td>
              <td className='text-center'>
                <Link to={graphUrl + item.id.S}>
                  <FcBullish />
                </Link>
              </td>
            </tr>
          )
        )
      : null
  )
  const sortButton = key => {
    if (sortConfig[0] !== key) {
      return (
        <FaSort
          type='button'
          onClick={() => requestSort(key)}
        />
      )
    } else if (sortConfig[1]) {
      return (
        <FaSortUp
          type='button'
          onClick={() => requestSort(key)}
        />
      )
    } else {
      return (
        <FaSortDown
          type='button'
          onClick={() => requestSort(key)}
        />
      )
    }
  }
  return (
    <Container>
      <Row className='mb-4'>
        <h1
          className='featurette-heading'
          style={{ fontWeight: '100' }}
        >
          View Historical Data
        </h1>
      </Row>
      <>
        <Card
          style={{
            fontWeight: '100',
            backgroundColor: '#caccd1',
            borderRadius: '5px',
            boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'
          }}
          className='lead mb-3'
        >
          <Card.Header as='lead'>
            Filter Table Entries
          </Card.Header>
          <Card.Body>
            <Form className='justify-content-around'>
              <Row className='mb-3'>
                  <Col xs='auto'>
                    <Form.Group>
                      <Form.Label> Station </Form.Label>
                      <Form.Select
                        size='md'
                        value={station}
                        onChange={e => {
                            setUnit('')
                            setStation(e.target.value)
                        }}
                      >
                        <option value=''> </option>
                        <AllStationOptions />
                      </Form.Select>
                    </Form.Group>
                  </Col>
                <Col xs='auto'>
                  <Form.Group>
                    <Form.Label> Unit </Form.Label>
                    <Form.Select
                      size='md'
                      value={unit}
                      onChange={e => setUnit(e.target.value)}
                    >
                      <option key='sel-0' value='' />
                      <AllUnitOptions station={station} />
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs='auto'>
                  <Form.Group>
                    <Form.Label> Cycle </Form.Label>
                    <Form.Control
                      size='md'
                      type='number'
                      min='0'
                      max='1000'
                      value={cycle}
                      onChange={e => setCycle(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs='auto'>
                  <Form.Group>
                    <Form.Label>Date</Form.Label>
                    <div>
                      <DayPickerInput
                        value={day}
                        onDayChange={(day, mods, input) => {
                          setDay(day)
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col xs='auto'>
                  {
                    (props.personalView)
                      ? (
                        <Form.Group>
                          <Form.Label>Owner</Form.Label>
                          <Form.Control
                            type='text'
                            size='md'
                            value={fullName}
                            disabled
                          />
                        </Form.Group>
                        )
                      : (
                        <Form.Group>
                          <Form.Label>Owner</Form.Label>
                          <Form.Control
                            type='text'
                            size='md'
                            value={owner}
                            onChange={(e) => {
                              setOwner(e.target.value)
                            }}
                            placeholder='LAST, FIRST'
                          />
                        </Form.Group>
                        )
                  }
                </Col>
                <Col xs='auto'>
                  <Form.Group>
                    <Form.Label> Notes Contain </Form.Label>
                    <Form.Control
                      type='text'
                      size='md'
                      placeholder=''
                      value={notes}
                      onChange={e => setNotes(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col xs='auto'>
                  <Form.Switch
                    type='switch'
                    label='Personal View'
                    title='Toggle between Personal and Company View'
                    checked={props.personalView}
                    onChange={props.togglePersonalView}
                  />
                </Col>
                <br /><br />
                <Row className='mb-3'>
                  <Col xs='10'>
                    <Button
                      size='sm'
                      type='button'
                      title='Refresh Historical Data'
                      variant='outline-primary'
                      style={{ borderRadius: '25px' }}
                      onClick={() => {
                        unselect()
                        props.fetch()
                      }}
                    >
                      <FcSynchronize size={24} />
                      &nbsp;
                      Refresh Data
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </>
      <Row className='mb-3'>
        <Col>
          <Table striped bordered hover style={{ boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)' }}>
            <thead>
              <tr>
                <th> </th>
                <th>
                  Station
                  {sortButton('station')}
                </th>
                <th className='text-center'>
                  Unit
                  {sortButton('unit')}
                </th>
                <th className='text-center'>
                  Cycle
                  {sortButton('cycle')}
                </th>
                <th>
                  Owner
                  {sortButton('owner')}
                </th>
                <th>
                  Upload (EST)
                  {sortButton('timestamp')}
                </th>
                <th>
                  Date Range
                </th>
                <th className='text-center'>Cycle Coverage</th>
                <th className='text-center'>
                  Notes
                </th>
                <th className='text-center'>
                  File
                </th>
                <th className='text-center'>
                  Graph
                </th>
              </tr>
            </thead>
            <tbody>
              {tableBody}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row xs='auto' className='justify-content-center'>
        <Col>
          {thisPagination}
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col>
          <Dropdown drop='up'>
            <Dropdown.Toggle variant='secondary' id='dd-action'>
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ backgroundColor: '#73a47' }}>
              <Dropdown.Item>
                <Link style={{ textDecoration: 'none' }} to='/mco/history/add'>
                  Add Historical Cycle Data
                </Link>
              </Dropdown.Item>
              <Dropdown.Item onClick={downloadFiles}>
                Download
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </Container>
  )
}

HistoryIndexPage.Layout = Layout

export default HistoryIndexPage
